import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueCookies from 'vue-cookies'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'


import {validateTokenRefreshData} from '@/auth/utils'
//elementor visual builder
import {BootstrapVue,BootstrapVueIcons} from "bootstrap-vue"
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@/assets/builder_elementor/styles/evan-custom.css";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

setTimeout(async () => {
  await validateTokenRefreshData()
}, 500)

// Global Components
import './global-components'

//socket-io
import  "@/libs/socket-io/socket"

//global filter
Vue.filter('round', (number) => {
  if (isNaN(number) || !number)
    return 0
  return parseFloat(number).toFixed(2);
});

Vue.filter('toCurrency', function (value) {
  if (isNaN(value) || !value)
    return 0.00
  let formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: "code"
  });
  return formatter.format(value).replace("USD", "").trim();
});

// 3rd party plugins
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'
import axiosWhitelabel from '@/libs/axios'
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
// Composition API
Vue.use(VueCompositionAPI)
Vue.use(VueCookies)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard
// import core styles
require('@core/scss/core.scss')
// import assets styles
require('@/assets/scss/style.scss')



//elementor visual builder
Vue.use(VueSweetalert2);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

Vue.config.productionTip = false
Vue.prototype.$http = axiosWhitelabel

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
