<template>
  <header ref="header">
    <div class="w-full pt-3 px-3 bg-cover header-content" :style="Style">
      <div
        :class="'grid' + ' grid-cols-' + headerCols + ' gap-4 '"
        style="place-items: center; max-width: 1200px; margin: auto"
      >
        <div
          v-if="!header.title.hide"
          class="header-title d-nonde d-sm-block"
          :class="`order-${header.title.position}`"
        >
          <h1 :style="styleTitle(header.title)">
            {{ header.title.text }}
          </h1>
        </div>
        <div
          v-if="header.logo && !header.logo.hide"
          id="header-logo"
          class="header-logo"
          :class="`order-${header.logo.position}`"
        >
          <a to="/" class="flex items-center justify-center">
            <img class="img-header-logo" alt="logo" :src="header.logo.href" />
          </a>
        </div>
        <div class="header-auth" :class="`order-${header.auth.position}`">
          <div class="d-flex flex-column align-items-center w-100">
            <div
              id="header-auth-r-l"
              class="flex justify-center w-100 container-main-buttons"
            >
              <register
                :config="header.auth"
                :type="type"
                :currencies="currencies"
                v-if="!header.auth.hide"
              ></register>
              <AppLogin :config="header.auth" />
            </div>
            <div
              id="header-auth-c-l"
              class="flex flex-col flex-wrap justify-center align-center pb-2"
            >
              <reloj></reloj>
              <locate></locate>
            </div>
            <!--            <small v-if="lastLoginDate" class="small-lastLogin pb-2">-->
            <!--              Last login 23/12/04-->
            <!--            </small>-->
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import Register from "../../modules/auth/register/AppRegister.vue";
import Locate from "../../modules/locate/AppLocate.vue";
import Reloj from "../../modules/clock/AppClock.vue";
import AppLogin from "@/views/components/whitelabel-templates/App/components/modules/auth/login/AppLogin.vue";
export default {
  components: {
    AppLogin,
    Register,
    Locate,
    Reloj,
  },
  data() {
    return {};
  },
  props: ["type", "header", "users", "currencies"],
  computed: {
    Style() {
      const hasBackgroundImage = this.header.background_image && this.header.background_image.trim() !== '';

      return {
        "background-image": hasBackgroundImage ? `url('${this.header.background_image}')` : "none",
        "background-color": this.header.background_color
          ? this.header.background_color
          : "",
        "--logo-w": this.header.logo.width + "px" || "100%",
        "--logo-h": this.header.logo.height + "px" || "",
        "max-height": "280px",
      };
    },
    headerCols() {
      let cols = 3;
      if (this.header.logo.hide) cols -= 1;
      if (this.header.hide) cols -= 1;
      if (this.header.title.hide) cols -= 1;
      return cols;
    },
  },
  methods: {
    styleTitle(title) {
      return {
        color: title.color ? title.color : "white",
        "font-size": title.font_size ? `${title.font_size}px` : "",
      };
    },
  },
  mounted() {
    this.$root.$on("highlightComponent", (e) => {
      if (e === "Header") {
        this.$refs.header.style.transform = "scale(1.01)";
        this.$refs.header.style.transition = "all .3s ease-in-out";
      } else {
        this.$refs.header.style.transform = "";
      }
    });
  },
};
</script>
<style scoped>
.header-container {
  height: 15rem;
  width: 100%;
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;
  justify-content: space-between;
  align-items: center;
}
.header-auth {
  width: 100%;

  font-size: 0.6rem;
}
.header-logo {
  width: 70%;
  align-items: center;
  justify-content: center;
}

.header-auth,
.header-title {
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-header-logo {
  width: 100%;
  max-width: var(--logo-w);
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}
.small-lastLogin {
  color: #fff;
}

@media screen and (max-width: 450px) {
  .container-main-buttons {
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: center;
  }
}
@media screen and (min-width: 1024px) {
  .header-auth,
  .header-logo,
  .header-title {
    width: fit-content;
  }
  .header-auth {
    font-size: 0.8rem;
  }
  .img-header-logo {
    width: var(--logo-w);
    height: var(--logo-h);
  }
}
</style>
