<script>
import ApostalaLobbyTags from "../../components/modules/lobby/ApostalaLobbyTags.vue";
import ApostalaCarousel from "@/views/components/whitelabel-templates/Apostala/components/common/ApostalaCarousel.vue";
import ApostalaMoreProducts from "@/views/components/whitelabel-templates/Apostala/components/modules/section/more-products/ApostalaMoreProducts.vue";
import ApostalaLobbyProviders from "@/views/components/whitelabel-templates/Apostala/components/modules/section/providers/ApostalaLobbyProviders.vue";
import ApostalaGamesList from "@/views/components/whitelabel-templates/Apostala/components/modules/section/games/ApostalaGamesList.vue";
import ImageBg from "@/views/components/whitelabel-templates/Apostala/components/common/ImageBg.vue";

export default {
  name: "ApostalaHomePage",
  components: {
    ImageBg,
    ApostalaGamesList,
    ApostalaMoreProducts,
    ApostalaLobbyProviders,
    ApostalaCarousel,
    ApostalaLobbyTags,
  },
  props: ["carousel", "products", "bgImage"],
};
</script>

<template>
  <div class="overflow-hidden relative homePage" ref="homePage">
    <apostala-carousel :carousel="carousel" />

    <!--TODO CHANGE THIS-->
    <apostala-lobby-tags />

    <apostala-lobby-providers />

    <image-bg :bg="bgImage"> 

    <apostala-games-list title="Best games" />

    <apostala-more-products :products="products" />
    
    </image-bg>
  </div>
</template>

<style scoped>
.homePage{
  background-color: #141414;
}
</style>
