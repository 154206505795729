<script>
import {BButton, BFormInput} from "bootstrap-vue";
import store from "@/store";
import whitelabelTemplatesModule from "@/views/pages/templates/store/templatesStoreModule";
import {
  computed,
  onMounted,
  onBeforeUnmount,
  ref,
} from "@vue/composition-api";
import {useToast} from "vue-toastification/composition";
import formValidation from "@core/comp-functions/forms/form-validation";
import kbStoreModule from "@/views/components/whitelabel-templates/KB/store/kbStoreModule";
import {
  showErrorToast,
  showSuccessToast,
} from "@/views/components/whitelabel-templates/common/utils/showToast";
import {axiosErrorHandle} from "@core/utils/errorHandler";
import i18n from "@/libs/i18n";

export default {
  name: "GEditColors",
  components: {BButton, BFormInput},
  setup() {
    const MODULE_APP_STORE_NAME = "app-whitelabel-templates";
    const MODULE_AP_STORE_NAME = "ap-store";

    if (!store.hasModule(MODULE_APP_STORE_NAME))
      store.registerModule(MODULE_APP_STORE_NAME, whitelabelTemplatesModule);
    if (!store.hasModule(MODULE_AP_STORE_NAME))
      store.registerModule(MODULE_AP_STORE_NAME, kbStoreModule);

    const toast = useToast();
    const debounceTimer = ref(null);

    const colors = ref({
      main: {
        primaryColor: "",
        game_text_color: "",
        home_page_background_color: "",
        home_page_hover_color: "",
        home_page_primary_color: "",
        home_page_secondary_color: "",
        home_page_text_color: "",
        providers_background_color: "",
        providers_hover_color: "",
        providers_primary_color: "",
        providers_secondary_color: "",
        providers_text_color: "",
        tags_background_color: "",
        tags_hover_color: "",
        tags_text_color: "",
        divider_color: ""
      },
      userProfile: {
        bgPrimary: "",
        bgSecondary: "",
        colorBorder: "",
        colorPrimary: "",
        colorPrimaryText: "",
        colorSecondary: ""
      },
    });
    const loading = ref(false);

    const editTemplate = computed(
        () => store.state[MODULE_APP_STORE_NAME].editTemplate
    );

    const updateLocalColors = () => {
      colors.value.main = editTemplate.value.templateData.main.colors;
      colors.value.userProfile =
          editTemplate.value.templateData.user_profile.colors;
    };


    onMounted(() => {
      updateLocalColors();
    });

    const updateTemplate = async () => {
      loading.value = true;
      try {
        const response = await store.dispatch(
            `${MODULE_APP_STORE_NAME}/updateTemplate`,
            {
              template: editTemplate.value,
              toast,
              path: 'templateData.main'
            }
        );

        showSuccessToast(toast, "Colors", i18n.t("colors_updated"));
        updatePreview(response.data);
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_updating_colors"),
            axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const changePreview = (value) => {
      store.commit("ap-store/SWITCH_PREVIEW", value);
    };

    onBeforeUnmount(() => {
      if (debounceTimer.value) {
        clearTimeout(debounceTimer.value);
      }
      changePreview("general");
    });


    const {refFormObserver, getValidationState, resetForm} = formValidation(
        () => {
        }
    );

    const updatePreview = () => {
      const updatedTemplate = {
        ...editTemplate.value,
        templateData: {
          ...editTemplate.value.templateData,
          main: {
            ...editTemplate.value.templateData.main,
            colors: colors.value.main
          },
          user_profile: {
            ...editTemplate.value.templateData.user_profile,
            colors: colors.value.userProfile
          }
        }
      };
      store.commit(`${MODULE_APP_STORE_NAME}/SET_EDIT_TEMPLATE`, updatedTemplate);
    };

    const onColorChange = ({key, value, section}) => {
      colors.value[section][key] = value;

      if (debounceTimer.value) {
        clearTimeout(debounceTimer.value);
      }

      debounceTimer.value = setTimeout(() => {
        updatePreview();
      }, 1000); // 5000 ms = 5 segundos
    };

    const formatLabel = (key) => {
      return key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    };

    const resetData = () => {
      store.commit("app-whitelabel-templates/RESET_EDIT_TEMPLATE","templateData.main");
      store.commit("app-whitelabel-templates/RE_RENDER_TEMPLATE","templateData.user_profile");
      updateLocalColors()
    };

    return {
      updateTemplate,
      resetData,
      editTemplate,
      refFormObserver,
      getValidationState,
      resetForm,
      colors,
      loading,
      changePreview,
      onColorChange,
      formatLabel
    };
  },
};
</script>

<template>
  <div>
    <div>
      <b-tabs content-class="mt-3">
        <b-tab :title="$t('main')" active @click="changePreview('general')">
          <div class="list-group list-group-flush">
            <a v-for="(value, key) in colors.main" :key="key"
               class="list-group-item border-0 list-group-item-action d-flex gap-3"
               aria-current="true">
              <div class="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 class="mt-1">{{ formatLabel(key) }}</h6>
                </div>
                <span class="color-box">
              <b-form-input
                  type="color"
                  :value="value"
                  @input="(newValue) => onColorChange({ key, value: newValue, section: 'main' })"
              />
            </span>
              </div>
            </a>
          </div>
        </b-tab>

        <b-tab :title="$t('user_profile')" @click="changePreview('profile')">
          <div class="list-group list-group-flush">
            <a v-for="(value, key) in colors.userProfile" :key="key"
               class="list-group-item border-0 list-group-item-action d-flex gap-3"
               aria-current="true">
              <div class="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 class="mt-1">{{ formatLabel(key) }}</h6>
                </div>
                <span class="color-box">
              <b-form-input
                  type="color"
                  :value="value"
                  @input="(newValue) => onColorChange({ key, value: newValue, section: 'userProfile' })"
              />
            </span>
              </div>
            </a>
          </div>
        </b-tab>
      </b-tabs>
    </div>

    <div class="row justify-content-end pt-4 pb-2">
      <div class="col-md-6">
        <b-button
            :disabled="loading"
            size="sm"
            class="w-100"
            variant="outline-primary"
            @click="resetData()"
        >
          {{ $t("buttons.cancel") }}
        </b-button>
      </div>

      <div class="col-md-6">
        <b-button
            :disabled="loading"
            class="w-100"
            @click="updateTemplate"
            size="sm"
            variant="primary"
        >
          Guardar
        </b-button
        >
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.list-group {
  max-height: 300px;
  overflow-y: scroll;
  width: 300px;
}

.color-box {
  width: 50px;
}
</style>
