<script>
export default {
  name: 'ImageBg',
  props: {
    bg: {
      type: String,
      default: ''
    },
  }
}

</script>
<template>
  <div class="custom-bg" :style="{ backgroundImage: `url(${bg})` }">
    <slot />
  </div>
</template>

<style scoped>
.custom-bg {
  background-repeat: repeat;
  background-size: contain;
}
</style>