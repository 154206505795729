<template>
  <div>
    <b-overlay opacity="0" :show="loadingData">
      <div class="tree" style="min-height:100px">
        <b-input-group>
          <b-form-input placeholder="" @change="reSearch" v-model="searcher" />
          <b-input-group-prepend>
            <b-button variant="outline-primary" @click="asyncLoadLazy()">
              <feather-icon icon="SearchIcon" />
            </b-button>
          </b-input-group-prepend>
        </b-input-group>
        <v-tree
          v-if="showTree"
          class="p-0 m-0"
          ref="treeAsync"
          :data="treeDataAsync"
          :radio="true"
          @node-click="selectUser"
          @async-load-nodes="asyncLoad"
        />
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { BInputGroup, BInputGroupAppend, BFormInput } from 'bootstrap-vue'
import { BOverlay } from 'bootstrap-vue'
import { VTree } from 'vue-tree-halower'
import 'vue-tree-halower/dist/halower-tree.min.css'
import store from '@/store'
import { onUnmounted, computed, watch } from '@vue/composition-api'

export default {
  components: {
    VTree,
    BOverlay,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
  },
  data: () => {
    return {
      initSelected: [],
      treeDataAsync: [],
      searcher: '',
      loadingData: false,
      userId: JSON.parse(localStorage.getItem('userData'))._id,
      timeOut: null,
      showTree: false,
    }
  },
  computed: {
    whitelabelSelect() {
      return this.$store.state.whitelabelCurrencyNabvar.whitelabel
    },
    currencieSelect() {
      return this.$store.state.whitelabelCurrencyNabvar.currency
    },
  },
  watch: {
    whitelabelSelect() {
      this.asyncLoadLazy()
    },
    currencieSelect() {
      this.asyncLoadLazy()
    },
  },

  methods: {

    reSearch() {
      if (this.searcher === '') {
        this.asyncLoadLazy()
      }
    },
    selectUser(node, select) {
      if (select) {
        this.$emit('selectUser', node)
        this.$emit('selectUserOne', node)
      } else {
        this.$emit('selectUser', { id: JSON.parse(localStorage.getItem('userData'))._id })
        this.$emit('selectUserOne', { id: JSON.parse(localStorage.getItem('userData'))._id })
      }
    },
    expandNodes(nodes) {
      nodes.forEach(node => {
        this.$set(node, 'expanded', true);

        if (node.children && node.children.length > 0) {
          this.expandNodes(node.children);
        }
      });
    },

    async cleanChildren(array, query) {
      if (query !== '') {
        try {
          return await Promise.all(array.map(async element => {
            let data = {
              id: element.id,
              title: element.title,
              expanded: false,
              async: true,
              children: []
            };

            if (element.children && element.children.length > 0) {
              data.children = await this.cleanChildren(element.children, query);
            }

            if (element.title.toLowerCase().includes(query.toLowerCase())) {
              data.expanded = true;
              if (data.children && data.children.length > 0) {
                data.children.forEach(child => this.$set(child, 'expanded', true));
              }
            }

            return data;
          }));
        } catch (error) {
          console.error("Error processing children:", error);
        }
      } else {
        try {
          return await array.map(element => {
            let data = {
              id: element.id,
              title: element.title,
              expanded: false
            }
            if (element.async) {
              data.async = true
            }
            return data
          })
        } catch (error) {
          console.error(error);
        }
      }
    },

    async asyncLoad(node) {
      try {
        if (this.loadingData) return;
        this.loadingData = true;

        let isRootTree = true
        
        if (node) {
          this.userId = node.id;
          isRootTree = false;
        } else {
          this.userId = JSON.parse(localStorage.getItem('userData'))._id;
          this.$emit('selectUser', { id: this.userId });
        }

        const { data } = await store.dispatch('hierarchyModule/getUsersHierarchy', {
          id: this.userId,
          whitelabel: this.whitelabelSelect?._id,
          currency: this.currencieSelect,
          query: this.searcher,
          isRootTree
        });

        if (!data.children) return;

        const arrayClean = await this.cleanChildren(data.children, this.searcher);

        if (node) {
          const { checked = false } = node;
          if (!node.hasOwnProperty('children')) {
            this.$set(node, 'children', []);
          }
          node.children.push(...arrayClean);

          if (checked) {
            this.$refs.treeAsync.childCheckedHandle(node, checked);
          }
        } else {
          const dataTree = [
            {
              id: data._id,
              title: data.title,
              expanded: false,
              async: true,
              children: arrayClean
            }
          ];
          this.treeDataAsync = dataTree;
          if (this.searcher !== '') {
            this.expandNodes(this.treeDataAsync);
          }
        }

        this.showTree = true;
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingData = false;
      }
    },

    asyncLoadLazy() {
      if (this.timeOut) {
        clearTimeout(this.timeOut);
      }
      this.timeOut = setTimeout(() => {
        this.asyncLoad();
      }, 500);
    }
  },

  created() {
    this.asyncLoad();
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/tree.scss";
</style>

<style lang="scss" scoped>
.tree {
  padding: 0 10px 0 15px;
}
</style>
