import axios from '@/libs/axios'
import store from '@/store'
import router from '@/router'
import {connectSocket} from "@/libs/socket-io/socket";
import i18n from "@/libs/i18n";
/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
// canbie la funcion para verificar el loggin donde hara si ya esta un token se hace una peticion de verificar el token en el backend
// y si no hay un token procedera a ver si hay un userData y hara login  
export const isUserLoggedIn = async () => {
  //const userToken = state.user.
  const userToken = store.state.user.access_token
  
  //const userData = JSON.parse(localStorage.getItem('userData'))
  if (!userToken) {
    return false
  }else {
    return true
  }
  // try{
  //   const { data } = await axios.post("/auth/check",{},{ headers:{ Authorization: "Bearer " + userToken }})
  //   return data.data.is_logged
  // } catch (err){
  //   return false
  // }
}

async function validarToken(){
  try {
      const { data } = await axios.get("/tokenCheck")
      // commit
      SET_APP_DATA(data.data)
      //socket-io connect to server
      connectSocket()
  } catch(err) {
    console.log(err)
    throw err;
  }
}

//An old implementation of these methods has been improved to avoid code duplication,
//and to be able to apply responsibilities separation
export const validateTokenRefreshData = async () => {
  try{
      await validarToken()
  } catch (err){
      await handleAuthError(err)
  }
}

//An old implementation of these methods has been improved to avoid code duplication,
//and to be able to apply responsibilities separation
async function handleAuthError(err) {
    if (err.message === "Request aborted") {
        setTimeout(async () => {
            await validateTokenRefreshData();
        }, 800);
    } else if(err.response?.data?.data?.whitelabel) {
        // save whitelabel data
        store.commit('user/SET_LOGO', err.response.data?.data?.whitelabel?.logo || '')
        store.commit('user/SET_WALLPAPE_URL', err.response.data?.data?.whitelabel?.wallpaperUrl)
        localStorage.setItem('logoFront', err.response.data?.data?.whitelabel?.logo || '')
        localStorage.setItem('wallpaperUrl', err.response.data?.data?.whitelabel?.wallpaperUrl || '')
    }  else {
        throw err;
    }
}

export const SET_APP_DATA =  (data, setUserToken = false) => {
    try {
        if (setUserToken){
          store.commit('user/SET_ACCESS_TOKEN', data.token)
          localStorage.setItem('access_token', data.token)
          store.commit('user/SET_REFRESH_TOKEN', data.refreshToken)
          localStorage.setItem('refresh_token', data.refreshToken)
        }
        SET_USER_DATA(data.user)
        store.commit('user/SET_PERMISSIONS_USER_LOGUE', data.permissions)
        localStorage.setItem('permissions', JSON.stringify(data.permissions))
        store.commit('whitelabelCurrencyNabvar/SET_WHITELABELS', data.whitelabels)
        localStorage.setItem('whitelabels', JSON.stringify(data.whitelabels))
    }catch (e){
        console.log(e)
    }
}

export const SET_USER_DATA = (user)=>{
    store.commit('user/SET_AUTH_USER', user)
    store.commit('whitelabelCurrencyNabvar/SET_INFO_USER', user)
    localStorage.setItem('userData', JSON.stringify(user))
}

export function checkUser2FA(userObject) {
    let check = false
    if (userObject.is2FAEnabled && userObject.type2FA !== 'NONE' && !userObject.was2FASucceedValidate) {
        check = true
    }
    return check
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
// export const getHomeRouteForLoggedInUser = userRole => {
//   if (userRole === 'admin') return { name: 'dashboard-ecommerce' }
//   if (userRole === 'client') return { name: 'dashboard-ecommerce' }
//   return { name: 'auth-login' }
// }
