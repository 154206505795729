import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function loadVeeValidateLocale(locale){
  let l = {code : '', messages : {}}
  if (locale === 'en') {
    l = require('vee-validate/dist/locale/en.json')
  } else if (locale === 'es') {
    l = require('vee-validate/dist/locale/es.json')
  }
  return l
}

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
      messages[locale].validations = loadVeeValidateLocale(locale)
      messages[locale].validations.messages = {
        ...messages[locale].validations.messages,
        ...messages[locale]['external_validations']
      }
    }
  })
  return messages
}

// This function sets the application's language based on the current URL or a stored preference.
const initializeLanguageBasedOnUrl = () => {
  // Define a mapping of URLs to their corresponding languages.
  const urlLanguageMap = [
    {
      url: 'admin.bahamasplay.com', // URL for the admin site
      language: 'en' // Language for the admin site
    },
    {
      url: 'localhost:8081', // URL for local development
      language: 'es' // Language for local development
    }
  ];

  // Default language is set to Spanish.
  let selectedLanguage = 'es'; // Default language

  try {
    // Get the current URL of the window.
    const currentUrl = window.location.href;
    // Check if the current URL matches any in the urlLanguageMap.
    const urlMatch = urlLanguageMap.find(item => currentUrl.includes(item.url));

    // Check if there is a language preference stored in localStorage.
    const storedLanguage = localStorage.getItem('lang');

    if (storedLanguage) {
      // If a stored language exists, use it as the selected language.
      selectedLanguage = storedLanguage; // Use the stored language
      console.log('storedLanguage:', selectedLanguage); // For debugging
    } else if (urlMatch) {
      // If there is a URL match, use the corresponding language from the mapping.
      selectedLanguage = urlMatch.language; // Use the language from the URL
      // Store the selected language in localStorage for future visits.
      localStorage.setItem('lang', selectedLanguage);
      console.log('urlMatch:', selectedLanguage); // For debugging
    }

  } catch (error) {
    // Log any errors that occur during the process.
    console.error('Error setting language:', error);
  }

  // Return the selected language.
  return selectedLanguage; // Return the selected language
};

export default new VueI18n({
  locale:  initializeLanguageBasedOnUrl(),
  fallbackLocale: 'es',
  messages: loadLocaleMessages(),
})
